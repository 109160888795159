import React, { FC } from "react"
// import { Link } from "gatsby"
import Head from "../../components/head"
import Header from "../../components/header"
import Footer from "../../components/footer"
import styles from "../../styles/global.css"
import contact from "../../styles/media_page.css"
import media from "../../styles/media.css"

export default () => (
  <>
    <Header titleText="Media - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
        <div className="clear_both"></div>
        <div className="loginheader_div">
          <div className="loginheader">
            <div className="loginheaderlist_div">
              <img
                alt="icon doller"
                src="/sites/default/files/icon_doller.png"
              />
              <a rel="" href="/pricing/">
                Pricing
              </a>
            </div>
            <div className="loginheaderlist_div">
              <img alt="icon trial" src="/sites/default/files/icon_trial.png" />
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=0"
                target="_blank"
              >
                Free Trial
              </a>
            </div>

            <div className="loginheaderlist_div">
              <img alt="user" src="/sites/default/files/user.png" />
              <span className="login_span">
                <a rel="" href="javascript:;">
                  Log In
                </a>
                <ul className="login_ul_son">
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://user.happymangocredit.com"
                      target="_blank"
                    >
                      Individual/Business
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://institution.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Institutions
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://advisor.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Coaches
                    </a>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Head />

      {/* <div className="banner_contact">
        <img
          className="contact_img"
          src="/images/fresh-leaves.jpg"
          alt="banner"
        />
        <h1 className="bannerinfo">Mango Slice Blog</h1>
      </div> */}

      <div className="media">
        <div id="preface" className="block">
          <div className="region region-preface">
            <div
              id="block-views-security-block"
              className="block block-views blue we-believe"
            >
              <div className="inner">
                <div className="content">
                  <div className="view view-security view-id-security view-display-id-block view-dom-id-09b08ef9d2ef556de55f0586d9ed9143">
                    <div className="view-content">
                      <div className="item">
                        <div>
                          <div className="title">
                            <div
                              style={{
                                "margin-top": "-10px",
                                "text-align": "left",
                                "font-size": "28px",
                                "font-weight": "bold",
                              }}
                            >
                              <h3>
                                Happy Mango Comments to NCUA on Proposed Payday
                                Alternative Loans
                              </h3>
                            </div>
                            <div>
                              <div>
                                <div
                                  className="excerpt"
                                  style={{
                                    display: "block",
                                    "text-align": "right",
                                    "font-size": "14px",
                                  }}
                                >
                                  <img
                                    src="/sites/all/themes/hmm/images/blogs/date-gray.png"
                                    style={{ width: "16px" }}
                                  />
                                  Aug 07, 2018
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="item">
                        <img
                          src="/sites/all/themes/hmm/images/blogs/paydayloans.jpg"
                          style={{
                            width: "100%",
                            "margin-top": "20px",
                            "margin-bottom": "20px",
                          }}
                        />
                        <div className="sharethis-inline-share-buttons"></div>
                        <div>
                          <p>
                            Happy Mango Inc. appreciates the opportunity to
                            comment on the
                            <a
                              href="https://www.gpo.gov/fdsys/pkg/FR-2018-06-04/pdf/2018-11591.pdf"
                              target="_blank"
                              rel="nofollow noopener"
                            >
                              proposed Payday Alternative Loans (PAL) II rule
                            </a>
                            .&nbsp;&nbsp;Happy Mango is a financial technology
                            company.&nbsp;&nbsp;We provide a low-cost online
                            lending platform that helps banks and credit unions
                            reduce processing costs and minimize loan
                            losses.&nbsp;&nbsp;&nbsp;
                          </p>
                          <p>
                            We applaud NCUA’s commitment to providing credit
                            union members affordable alternatives to payday
                            loans.&nbsp;&nbsp;We support NCUA’s permitting an
                            extra 1000 basis points on top of the statutory
                            interest rate for PAL loans so that credit unions
                            may offer affordable small-dollar loans as a viable
                            business.&nbsp;
                          </p>
                          <p>
                            <strong>
                              We believe additional PAL products (PAL III) are
                              necessary to meet credit union members’ needs and
                              to make PAL a sustainable business for credit
                              unions.&nbsp;
                            </strong>
                            In response to NCUA’s solicitation of comments on
                            the possibility of creating a third PALs loan
                            program (PALs III), we have the following
                            recommendations<strong>:</strong>
                          </p>
                          <p>
                            <strong>
                              1.&nbsp;&nbsp;&nbsp;&nbsp;Add line of credit as a
                              permissible product&nbsp;
                            </strong>
                          </p>
                          <p>
                            <strong>
                              2.&nbsp;&nbsp;&nbsp;&nbsp;Raise the interest rate
                              and application fee cap from those under PAL I and
                              PAL II
                            </strong>
                          </p>
                          <p>
                            We are making the above recommendations based on our
                            observation of the lending activities on our
                            platform and on research compiled from relevant
                            sources.&nbsp;
                          </p>
                          <h3>
                            <strong>
                              1.&nbsp;&nbsp;&nbsp;&nbsp;Add Line of Credit to
                              Meet the Growing Needs of Millions of Credit Union
                              Members
                            </strong>
                          </h3>
                          <p>
                            Expanding PAL with a line of credit can help
                            millions of credit union members who are at risk of
                            becoming payday borrowers.&nbsp;&nbsp;For lower
                            income consumers, many of them credit union members,
                            there is a shortage of affordable cash flow
                            management products, and this shortage will worsen
                            as income volatility rises.&nbsp;&nbsp;
                          </p>
                          <p>
                            Today credit card is the most common cash flow
                            management product in the
                            market.&nbsp;&nbsp;However, a significant number of
                            lower income consumers cannot get a credit
                            card.&nbsp;&nbsp;To qualify for a credit card, one
                            generally needs a credit score above 600. The
                            Consumer Financial Protection Bureau (CFPB)
                            estimates that 45 million Americans, or 20% of US
                            adult population[1], do not have credit score, and
                            another source estimates that one third of the
                            remaining 80% have scores below 600[2]. That is,
                            close to half of American consumers (approximately
                            100 million) do not qualify for credit cards will
                            need an alternative for managing cash flow
                            volatility.&nbsp;&nbsp;Many of them are credit union
                            members.
                          </p>
                          <p>
                            And their need will only grow because income
                            volatility is rising.&nbsp;&nbsp;A JP Morgan
                            Institute study[3]concluded that “Americans
                            experience tremendous income volatility, and that
                            volatility is on the rise.”&nbsp;The study estimates
                            that 55% people experience more than 30% change in
                            month-to-month income. With the continued expansion
                            of the online labor platforms (e.g. UBER, Handy)
                            which provides opportunistic but not so stable
                            sources of income, such volatility will only
                            rise.&nbsp;&nbsp;Without a line of credit, the most
                            convenient way to shore up cash flows for those
                            credit union members who do not qualify for credit
                            cards will be a payday loan.&nbsp;
                          </p>
                          <p>
                            Given the lack of an existing product to address the
                            growing cash flow challenges faced by a large number
                            of credit union members, we recommend expanding PAL
                            program to include line of credit as a permissible
                            product.
                          </p>
                          <h3>
                            <strong>
                              2.&nbsp;&nbsp;&nbsp;&nbsp;Raise Interest Rate and
                              Application Fee Cap to Make PAL a Sustainable
                              Program&nbsp;
                            </strong>
                          </h3>
                          <p>
                            Our financial forecast model indicates that small-
                            to mid-sized credit unions will likely lose money
                            participating in the current PAL program. Even large
                            credit unions will suffer months of loss before they
                            can ramp up the program to a scale of
                            self-sustainability. We recommend that the interest
                            rate cap be raised from 28% to 36% and that
                            application fee cap be raised from $20 to $50 so
                            that it will be economically viable for more credit
                            unions to participate in the PAL program.&nbsp;
                          </p>
                          <p>
                            With interest rate capped at 28% per annum and
                            application fee capped at $20, the unit revenue on
                            PAL products is extremely low. Even with complete
                            automation, PAL products under these terms won’t
                            break even until a certain scale is
                            reached.&nbsp;&nbsp;Some credit unions may never
                            reach that level of scale.&nbsp;&nbsp;For others,
                            the ramp up period to achieve such scale can last
                            from 6 months to 24 months, depending on the
                            portfolio mix and the credit union’s available
                            resources. During this time, the credit union will
                            have to sustain an operating loss in addition to
                            investing in marketing and technology to launch the
                            PAL program. The initial cost to launch and a
                            protracted period of negative net income may have
                            deterred many credit unions from participating in
                            the program.&nbsp;
                          </p>
                          <p>
                            Our revenue forecast model (see Exhibit A
                            below)&nbsp;
                            <u>
                              assumes complete automation and a write-off rate
                              of 5%.
                            </u>
                            The results indicate the following:
                          </p>
                          <ul>
                            <li>
                              PAL I at 28% interest rate and $20 application fee
                              reaches break-even at a scale of over
                              <strong>4,050</strong> applications per year or
                              340 applications per month;
                            </li>
                            <li>
                              PAL II at 28% interest rate and $20 application
                              fee reaches break-even at scale of&nbsp;over
                              <strong>360</strong> applications per year or 30
                              applications per month.&nbsp;
                            </li>
                          </ul>
                          <p>
                            Our experience indicates that it takes an average 6
                            to 12 months for mid-sized financial institutions
                            (assets between $150 million and $1 billion) with
                            moderate marketing budget to reach 30 applications
                            per month on a steady basis and more than 12 months
                            for large-sized financial institutions (assets above
                            $1 billion) with dedicated marketing department to
                            reach 350 applications per month.&nbsp;&nbsp;
                          </p>
                          <p>
                            Raising the interest rate cap to 36% and application
                            fee cap to $50 can shorten the time for PAL loans to
                            reach sustainability.&nbsp;With higher interest and
                            application fees,
                          </p>
                          <ul>
                            <li>
                              PAL I at 36% interest rate and $50 application fee
                              reaches break-even at a scale of over
                              <strong>1,545</strong> applications per year or
                              130 applications per month;
                            </li>
                            <li>
                              PAL II at 36% interest rate and $50 application
                              fee reaches break-even at a scale of over
                              <strong>246</strong> applications per year or 20
                              applications per month.
                            </li>
                          </ul>
                          <div className="slate-resizable-image-embed slate-image-embed__resize-full-width">
                            <img
                              style={{ width: "100%" }}
                              data-media-urn=""
                              data-li-src="https://media.licdn.com/dms/image/C4E12AQH5zUAx-e-TWQ/article-inline_image-shrink_1500_2232/0?e=1550102400&amp;v=beta&amp;t=l38AhXy1gk_2bxqaqcfC9l1PfUquarrWXtXvuDgyacg"
                              src="https://media.licdn.com/dms/image/C4E12AQH5zUAx-e-TWQ/article-inline_image-shrink_1500_2232/0?e=1550102400&amp;v=beta&amp;t=l38AhXy1gk_2bxqaqcfC9l1PfUquarrWXtXvuDgyacg"
                            />
                          </div>
                          <p>
                            Under the current caps, small- to mid-sized credit
                            unions (see Exhibit B below) will almost certainly
                            lose money by offering only PAL I products. They
                            will struggle to reach break-even even with the
                            subsidy of PAL II products. However, if the caps are
                            raised, then a small- to mid-sized credit union
                            could reach break-even with a balanced portfolio
                            when it reaches a scale of 900 applications a year
                            or 75 applications a month.&nbsp;
                          </p>
                          <p>
                            An 8% increase in interest rate and a $30 increase
                            in application fee cap from the current levels can
                            open the door for many more credit unions to
                            participate in the PAL program, offering consumers
                            significantly more attractive alternatives to payday
                            loans that charge over 400% in fees and
                            interests.&nbsp;&nbsp;
                          </p>
                          <div className="slate-resizable-image-embed slate-image-embed__resize-full-width">
                            <img
                              style={{ width: "100%" }}
                              data-media-urn=""
                              data-li-src="https://media.licdn.com/dms/image/C4E12AQFANuZAbywtng/article-inline_image-shrink_1500_2232/0?e=1550102400&amp;v=beta&amp;t=qQDjCcRG1cw0pebf5o-gqVl6_cEtu4dkOgR5_9zJmA4"
                              src="https://media.licdn.com/dms/image/C4E12AQFANuZAbywtng/article-inline_image-shrink_1500_2232/0?e=1550102400&amp;v=beta&amp;t=qQDjCcRG1cw0pebf5o-gqVl6_cEtu4dkOgR5_9zJmA4"
                            />
                          </div>
                          <p>
                            In conclusion, we believe PAL I and PAL II offer a
                            promising starting point from which the PAL program
                            can expand. The NCUA has taken admirable effort in
                            addressing the significant unmet consumer demand for
                            affordable cash-flow management
                            products.&nbsp;&nbsp;We look forward to supporting
                            this effort with cost-saving technology and helping
                            more credit unions participate in the PAL program in
                            a sustainable way.
                          </p>
                          <p>
                            <br />
                          </p>
                          <hr />
                          <p>
                            [1]The CFPB Office of Research, “Data Point: Credit
                            Invisibles” (2015)&nbsp;
                            <a
                              href="https://files.consumerfinance.gov/f/201505_cfpb_data-point-credit-invisibles.pdf"
                              target="_blank"
                              rel="nofollow noopener"
                            >
                              https://files.consumerfinance.gov/f/201505_cfpb_data-point-credit-invisibles.pdf
                            </a>
                          </p>
                          <p>
                            [2]Credit.com, “How Many Americans Have Bad Credit?
                            (2016)
                            <a
                              href="http://blog.credit.com/2016/02/how-many-americans-have-bad-credit-136868/"
                              target="_blank"
                              rel="nofollow noopener"
                            >
                              http://blog.credit.com/2016/02/how-many-americans-have-bad-credit-136868/
                            </a>
                          </p>
                          <p>
                            [3]JP Morgan Chase Institute, “Understanding Income
                            Volatility and the Role of the Online Platform
                            Economy” (2016)
                            <a
                              href="https://www.jpmorganchase.com/corporate/institute/insight-online-platform-econ-income-volatility.htm"
                              target="_blank"
                              rel="nofollow noopener"
                            >
                              https://www.jpmorganchase.com/corporate/institute/insight-online-platform-econ-income-volatility.htm
                            </a>
                          </p>
                        </div>
                        <p>&nbsp;</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  </>
)
